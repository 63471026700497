import { motion } from 'framer-motion';
import styled from 'styled-components';

export const NavbarDesktopOverall = styled(motion.div)`
  width: 100%;
  height: 64px;

  position: fixed;
  top: 0px;
  left: 0px;

  &:before {
    content: '';

    width: 100%;
    height: 64px;

    position: fixed;
    top: 0px;
    left: 0px;
    right: 0px;

    backdrop-filter: blur(24px);
  }

  background: ${({ theme }) => theme.backgroundMenuColor};

  ${({ theme }) =>
    !theme.isSpotlightOpen &&
    `
    box-shadow: 0px 0px 20px 0px rgba(23, 28, 51, 0.25);
  `}

  display: grid;
  grid-template-columns: minmax(0px, 1fr) minmax(min-content, 1440px) minmax(0px, 1fr);
  z-index: 99999;
`;

export const NavbarDesktopFiller = styled.div`
  ${({ theme }) =>
    theme.isSpotlightOpen &&
    `
    box-shadow: 0px 0px 20px 0px rgba(23, 28, 51, 0.25);
    clip-path: inset(0px 0px -20px 0px);
  `}

  position: relative;
`;

export const NavbarDesktopWrapper = styled.nav`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;

  position: relative;
`;

export const NavbarDesktopContainer = styled.div`
  width: 100%;
  height: 100%;

  max-width: 1440px;

  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;

  padding: 0px 24px;

  position: relative;
`;

export const NavbarDesktopLeftSectionWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;

  gap: 8px;
`;

export const NavbarDesktopRightSectionWrapper = styled.div`
  flex: 1;

  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;

  gap: 24px;
`;

export const NavbarDesktopMenuContainer = styled.div`
  width: 32px;
  height: 32px;

  display: flex;
  align-items: center;
  justify-content: center;

  border-radius: 8px;

  &:hover {
    background: rgba(23, 28, 51, 0.1);
  }

  cursor: pointer;
  user-select: none;

  position: relative;
`;

export const NavbarDesktopMenuTitleContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  padding: 4px 8px 2px 8px;
  border-radius: 8px;

  &:hover {
    background: rgba(23, 28, 51, 0.1);
  }

  cursor: pointer;
`;

//MENU
export const NavbarDesktopOpenMenuWrapper = styled.div`
  width: 100%;
  max-width: 1009px;

  position: absolute;
  bottom: 0px;
  left: 0px;

  border-radius: 0px 0px 48px 48px;
  box-shadow: rgba(23, 28, 51, 0.25) 0px 0px 20px 0px;

  clip-path: inset(0px -20px -20px -20px);

  transform: translateY(100%);
  overflow-y: hidden;
`;

export const NavbarDesktopOpenMenuContainer = styled(motion.div).attrs({
  initial: { maxHeight: 0 },
  animate: { maxHeight: 500 },
  exit: { maxHeight: 0 },
})``;

//SPOTLIGHT
export const NavbarDesktopSpotlightWrapper = styled(NavbarDesktopOpenMenuWrapper)`
  max-width: unset;
`;

export const NavbarDesktopSpotlightContainer = styled(motion.div).attrs({
  initial: { maxHeight: 0 },
  animate: { maxHeight: 500 },
  exit: { maxHeight: 0 },
})``;

//USER DROPDOWN
export const NavbarDesktopUserDropdownWrapper = styled.div`
  width: 100%;
  max-width: 276px;

  position: absolute;
  bottom: 0px;
  right: 0px;

  border-radius: 0px 0px 24px 24px;
  box-shadow: rgba(23, 28, 51, 0.25) 0px 0px 20px 0px;
  clip-path: inset(0px -20px -20px -20px);

  transform: translateY(100%);
  overflow-y: hidden;
`;

export const NavbarDesktopUserDropdownContainer = styled(motion.div).attrs({
  initial: { maxHeight: 0 },
  animate: { maxHeight: 500 },
  exit: { maxHeight: 0 },
})``;
