import { createGlobalStyle } from 'styled-components';
import styled from 'styled-components/macro';

/* istanbul ignore next */
export const GlobalStyle = createGlobalStyle`
  @charset "UTF-8";

  /* Chrome, Safari, Edge, Opera */
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  /* Firefox */
  input[type=number] {
    -moz-appearance: textfield;
  }

  @keyframes pulse {
    0% {
      background-color: #94A3B8;
    }
    50% {
      background-color: #CBD5E1;
    }
    100% {
      background-color: #94A3B8;
    }
  }

  :root {
    --color-gray: #8f8f92;
    --color-lightgray: #ADADAD;
    --color-primary: #171C33;
    --color-person: #FFC229;
    --color-people: #FFC229;
    --color-people_pill: #FFD25F;
    --color-passions: #D47563;
    --color-passions-secondary: #C36452;
    --color-passion: #D47563;
    --color-skills: #EB823D;
    --color-kyu_skill: #EB823D;
    --color-kyu_skills: #EB823D;
    --color-companies: #9CCAFF;
    --color-kyu_company: #9CCAFF;
    --color-kyu_companies: #9CCAFF;
    --color-clients: #96D7D9;
    --color-clients-secondary: #8CD4D6;
    --color-client_company: #96D7D9;
    --color-client_companies: #96D7D9;
    --color-industry: #8DA76C;
    --color-industries: #8DA76C;
    --color-industries-secondary: #CEF29E;
    --color-projects: #937ecc;
    --color-project: #937ecc;
    --color-projects-secondary: #392B62;
    --color-gathering: #f5faff;
    --color-gathering-secondary: #d4dadd;
    --color-communities: #D1B2B2;
    --color-communities-secondary: #A26565;
    --color-secondary: #8CD4D6;
    --color-text: #171C33;
    --color-error: #EB3D3D;
    --color-theme: var(--color-primary);
    --color-madlibs-passion: #FBAFA1;

    --color-dark-theme-background-secondary: #323F5D;


    /* Data */
    --color-data-community-background: #D1B2B2;
    --color-data-community-background-hover: #BE9292;
    --color-data-community-foreground: #A26565;
    --color-data-community-background-secondary: rgba(162, 101, 101, 0.3);
    --color-data-community-dark-theme-background: rgba(209, 178, 178, 0.4);

    --color-data-client-background: #96D7D9;
    --color-data-client-background-hover: #84BEBF;
    --color-data-client-foreground: #104D5B;
    --color-data-client-background-secondary: rgba(16, 77, 91, 0.3);
    --color-data-client-dark-theme-background: rgba(150, 215, 217, 0.4);

    --color-data-industry-background: #CEF29E;
    --color-data-industry-background-hover: #B1D384;
    --color-data-industry-foreground: #8DA76C;
    --color-data-industry-background-secondary: rgba(141, 167, 108, 0.3);
    --color-data-industry-dark-theme-background: rgba(206, 242, 158, 0.4);

    --color-data-alumni-background: #ADADAD;
    --color-data-alumni-background-hover: #949494;
    --color-data-alumni-foreground: #878787;
    --color-data-alumni-background-secondary: rgba(135, 135, 135, 0.3);
    --color-data-alumni-dark-theme-background: rgba(173, 173, 173, 0.3);

    --color-data-people-background: #FFD25F;
    --color-data-people-background-hover: #F5BC2C;
    --color-data-people-foreground: #FFC229;
    --color-data-people-background-secondary: rgba(255, 194, 41, 0.15);
    --color-data-people-dark-theme-background: rgba(255, 210, 95, 0.4);

    --color-data-city-background: #FFD25F;
    --color-data-city-background-hover: #F5BC2C;
    --color-data-city-foreground: #FFC229;
    --color-data-city-background-secondary: rgba(255, 194, 41, 0.15);
    --color-data-city-dark-theme-background: rgba(255, 210, 95, 0.4);

    --color-data-language-background: #FFD25F;
    --color-data-language-background-hover: #F5BC2C;
    --color-data-language-foreground: #FFC229;
    --color-data-language-background-secondary: rgba(255, 194, 41, 0.15);
    --color-data-language-dark-theme-background: rgba(255, 210, 95, 0.4);

    --color-data-skill-background: #FFA366;
    --color-data-skill-background-hover: #E5945C;
    --color-data-skill-foreground: #EB823D;
    --color-data-skill-background-secondary: rgba(235, 130, 61, 0.3);
    --color-data-skill-dark-theme-background: rgba(255, 163, 102, 0.4);

    --color-data-kyu_skill-background: #FFA366;
    --color-data-kyu_skill-background-hover: #E5945C;
    --color-data-kyu_skill-foreground: #EB823D;
    --color-data-kyu_skill-background-secondary: rgba(235, 130, 61, 0.3);
    --color-data-kyu_skill-dark-theme-background: rgba(255, 163, 102, 0.4);

    --color-data-passion-background: #FBAFA1;
    --color-data-passion-background-hover: #E99080;
    --color-data-passion-foreground: #D47563;
    --color-data-passion-background-secondary: rgba(212, 117, 99, 0.3);
    --color-data-passion-dark-theme-background: rgba(251, 175, 161, 0.4);

    --color-data-project-background: #9F7CFB;
    --color-data-project-background-hover: #886BD6;
    --color-data-project-foreground: #392B62;
    --color-data-project-background-secondary: rgba(57, 43, 98, 0.3);
    --color-data-project-dark-theme-background: rgba(159, 124, 251, 0.4);

    --color-data-company-background: #9CCAFF;
    --color-data-company-background-hover: #7CACE1;
    --color-data-company-foreground: #5478A1;
    --color-data-company-background-secondary: rgba(84, 120, 161, 0.3);
    --color-data-company-dark-theme-background: rgba(156, 202, 255, 0.4);

    --navbar-height: 64px;
  }

  .highlight-client-company {
    color: #323F5D!important;
    font-weight: 600!important;
  }
  .highlight-project {
    color: #323F5D!important;
    font-weight: 600!important;
  }
  .highlight-kyu-company {
    color: #323F5D!important;
    font-weight: 600!important;
  }
  .highlight-people {
    color: #323F5D!important;
    font-weight: 600!important;
  }
  .highlight-skills {
    color: #323F5D!important;
    font-weight: 600!important;
  }
  .highlight-passion {
    color: #323F5D!important;
    font-weight: 600!important;
  }
  .highlight-industry {
    color: #323F5D!important;
    font-weight: 600!important;
  }
  .highlight-community {
    color: #323F5D!important;
    font-weight: 600!important;
  }

  html,
  body {
    ${({ onboardingOpen }) => (onboardingOpen ? 'overflow-y: hidden;' : '')}
    height: 100%;
    width: 100%;
    line-height: 1.5;
    box-sizing: border-box;
    background: #fff;
    font-family: 'DM Sans', sans-serif;
  }

  * {
    color: var(--color-text);
    box-sizing: border-box;
    font-family: 'DM Sans', sans-serif!important;
  }

  p,
  label {
    line-height: 1.5em;
  }

  input, select, button {
    font-family: inherit;
    font-size: inherit;
    border: none;
    outline: none;
    box-shadow: none;
    padding: 0;
    background: none;
  }

  button {
    cursor: pointer;
  }

  .icon {
    width: 1.5rem;
    height: 1.5rem;
  }

  /* Document
    ========================================================================== */

  /**
   * 1. Correct the line height in all browsers.
   * 2. Prevent adjustments of font size after orientation changes in iOS.
   */

  html {
    line-height: 1.15; /* 1 */
    -webkit-text-size-adjust: 100%; /* 2 */
  }

  /* Sections
    ========================================================================== */

  /**
   * Remove the margin in all browsers.
   */

  body {
    margin: 0;
  }

  main {
    display: block;
  }

  h1 {
    font-size: 2em;
    margin: 0.67em 0;
  }

  /* Grouping content
    ========================================================================== */

  /**
   * 1. Add the correct box sizing in Firefox.
   * 2. Show the overflow in Edge and IE.
   */

  hr {
    box-sizing: content-box; /* 1 */
    height: 0; /* 1 */
    overflow: visible; /* 2 */
  }


  pre {
    font-family: monospace, monospace; /* 1 */
    font-size: 1em; /* 2 */
  }

  /* Text-level semantics
    ========================================================================== */

  /**
   * Remove the gray background on active links in IE 10.
   */

  a {
    background-color: transparent;
  }

  /**
   * 1. Remove the bottom border in Chrome 57-
   * 2. Add the correct text decoration in Chrome, Edge, IE, Opera, and Safari.
   */

  abbr[title] {
    border-bottom: none; /* 1 */
    text-decoration: underline; /* 2 */
    text-decoration: underline dotted; /* 2 */
  }

  /**
   * Add the correct font weight in Chrome, Edge, and Safari.
   */

  b,
  strong {
    font-weight: bolder;
  }

  code,
  kbd,
  samp {
    font-family: monospace, monospace; /* 1 */
    font-size: 1em; /* 2 */
  }

  /**
   * Add the correct font size in all browsers.
   */

  small {
    font-size: 80%;
  }

  sub,
  sup {
    font-size: 75%;
    line-height: 0;
    position: relative;
    vertical-align: baseline;
  }

  sub {
    bottom: -0.25em;
  }

  sup {
    top: -0.5em;
  }

  /* Embedded content
    ========================================================================== */

  /**
   * Remove the border on images inside links in IE 10.
   */

  img {
    border-style: none;
  }

  /* Forms
    ========================================================================== */

  /**
   * 1. Change the font styles in all browsers.
   * 2. Remove the margin in Firefox and Safari.
   */

  button,
  input,
  optgroup,
  select,
  textarea {
    font-family: inherit; /* 1 */
    font-size: 100%; /* 1 */
    line-height: 1.15; /* 1 */
    margin: 0; /* 2 */
  }

  /**
   * Show the overflow in IE.
   * 1. Show the overflow in Edge.
   */

  button,
  input {
    /* 1 */
    overflow: visible;
  }

  /**
   * Remove the inheritance of text transform in Edge, Firefox, and IE.
   * 1. Remove the inheritance of text transform in Firefox.
   */

  button,
  select {
    /* 1 */
    text-transform: none;
  }

  /**
   * Correct the inability to style clickable types in iOS and Safari.
   */

  button,
  [type='button'],
  [type='reset'],
  [type='submit'] {
    -webkit-appearance: button;
  }

  /**
   * Remove the inner border and padding in Firefox.
   */

  button::-moz-focus-inner,
  [type='button']::-moz-focus-inner,
  [type='reset']::-moz-focus-inner,
  [type='submit']::-moz-focus-inner {
    border-style: none;
    padding: 0;
  }

  /**
   * Restore the focus styles unset by the previous rule.
   */

  button:-moz-focusring,
  [type='button']:-moz-focusring,
  [type='reset']:-moz-focusring,
  [type='submit']:-moz-focusring {
    outline: 1px dotted ButtonText;
  }

  /**
   * Correct the padding in Firefox.
   */

  fieldset {
    padding: 0.35em 0.75em 0.625em;
  }

  legend {
    box-sizing: border-box; /* 1 */
    color: inherit; /* 2 */
    display: table; /* 1 */
    max-width: 100%; /* 1 */
    padding: 0; /* 3 */
    white-space: normal; /* 1 */
  }

  /**
   * Add the correct vertical alignment in Chrome, Firefox, and Opera.
   */

  progress {
    vertical-align: baseline;
  }

  /**
   * Remove the default vertical scrollbar in IE 10+.
   */

  textarea {
    overflow: auto;
  }

  /**
   * 1. Add the correct box sizing in IE 10.
   * 2. Remove the padding in IE 10.
   */

  [type='checkbox'],
  [type='radio'] {
    box-sizing: border-box; /* 1 */
    padding: 0; /* 2 */
  }

  /**
   * Correct the cursor style of increment and decrement buttons in Chrome.
   */

  [type='number']::-webkit-inner-spin-button,
  [type='number']::-webkit-outer-spin-button {
    height: auto;
  }

  /**
   * 1. Correct the odd appearance in Chrome and Safari.
   * 2. Correct the outline style in Safari.
   */

  [type='search'] {
    -webkit-appearance: textfield; /* 1 */
    outline-offset: -2px; /* 2 */
  }

  /**
   * Remove the inner padding in Chrome and Safari on macOS.
   */

  [type='search']::-webkit-search-decoration {
    -webkit-appearance: none;
  }

  ::-webkit-file-upload-button {
    -webkit-appearance: button; /* 1 */
    font: inherit; /* 2 */
  }

  /* Interactive
    ========================================================================== */

  /*
  * Add the correct display in Edge, IE 10+, and Firefox.
  */

  details {
    display: block;
  }

  /*
  * Add the correct display in all browsers.
  */

  summary {
    display: list-item;
  }

  /* Misc
    ========================================================================== */

  /**
   * Add the correct display in IE 10+.
   */

  template {
    display: none;
  }

  /**
   * Add the correct display in IE 10.
   */

  [hidden] {
    display: none;
  }

  html, body, div, span, applet, object, iframe,
  h1, h2, h3, h4, h5, h6, p, blockquote, pre,
  a, abbr, acronym, address, big, cite, code,
  del, dfn, em, img, ins, kbd, q, s, samp,
  small, strike, strong, sub, sup, tt, var,
  b, u, i, center,
  dl, dt, dd, ol, ul, li,
  fieldset, form, label, legend,
  table, caption, tbody, tfoot, thead, tr, th, td,
  article, aside, canvas, details, embed, 
  figure, figcaption, footer, header, hgroup, 
  menu, nav, output, ruby, section, summary,
  time, mark, audio, video {
    margin: 0;
    padding: 0;
    border: 0;
    font-size: 100%;
    font: inherit;
    vertical-align: baseline;
  }
  /* HTML5 display-role reset for older browsers */
  article, aside, details, figcaption, figure, 
  footer, header, hgroup, menu, nav, section {
    display: block;
  }
  body {
    ${({ isDrawerOpen }) => isDrawerOpen && `overflow: hidden;`}
    line-height: 1;
  }
  ol, ul {
    list-style: none;
  }
  blockquote, q {
    quotes: none;
  }
  blockquote:before, blockquote:after,
  q:before, q:after {
    content: '';
    content: none;
  }
  table {
    border-collapse: collapse;
    border-spacing: 0;
  }

  .dot-flashing {
    position: absolute;
    width: 10px;
    height: 10px;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    border-radius: 5px;
    background-color: #9880ff;
    color: #9880ff;
    animation: dotFlashing 1s infinite linear alternate;
    animation-delay: .5s;
  }

  .dot-flashing::before, .dot-flashing::after {
    content: '';
    display: inline-block;
    position: absolute;
    top: 0;
  }

  .dot-flashing::before {
    left: -15px;
    width: 10px;
    height: 10px;
    border-radius: 5px;
    background-color: #9880ff;
    color: #9880ff;
    animation: dotFlashing 1s infinite alternate;
    animation-delay: 0s;
  }

  .dot-flashing::after {
    left: 15px;
    width: 10px;
    height: 10px;
    border-radius: 5px;
    background-color: #9880ff;
    color: #9880ff;
    animation: dotFlashing 1s infinite alternate;
    animation-delay: 1s;
  }

  .hidden-img {
    display: none;
  }

  @keyframes dotFlashing {
    0% {
      background-color: #9880ff;
    }
    50%,
    100% {
      background-color: #ebe6ff;
    }
  }

  @font-face {
    font-family: 'GT Walsheim Bold';
    src: url('/fonts/GT-Walsheim/GT-Walsheim-Bold.woff2') format('woff2'),
      url('/fonts/GT-Walsheim/GT-Walsheim-Bold.woff') format('woff'),
      url('/fonts/GT-Walsheim/GT-Walsheim-Bold.ttf') format('truetype');
  }
`;

export const AppWrapper = styled.div``;
